import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"

import getRoomLink from "../../utils/get-room-link"

const LastSlideWrapper = styled.main`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2.5rem;
  text-align: center;
  align-self: flex-start;
`

const LastSlideInner = styled.div`
  .button-container {
    width: 10rem;

    a {
      ${props => props.buttonStyle}
    }

    a:first-child {
      margin-bottom: 2rem;
    }
  }

  @media (orientation: portrait) {
    .button-container {
      margin: 0 auto;
    }
  }

  @media (orientation: landscape) {
    display: flex;
    justify-content: center;
    align-items: center;

    .button-container {
      margin-left: 4rem;
    }
  }
`

const buttonStyle = `
  min-width: 8rem;
  white-space: nowrap;

  > span {
    display: block;
    text-align: center;
  }

  @media (orientation: portrait) {
    display: block;
    margin: 0 auto;
  }

  @media (orientation: landscape) {
    margin: 0 1rem;
  }
`

const StyledLink = styled(Link)`
  ${props => props.buttonStyle}
  ${buttonStyle}
  display: inline-block;
  text-decoration: none;
`

export default function LastSlide({ lang, room = 3, basePath, image, buttonStyle }) {
  const intl = useIntl()

  return (
    <LastSlideWrapper>
      <LastSlideInner buttonStyle={buttonStyle}>
        {image}
        <div className="button-container">
          <StyledLink to={`/${lang}/${basePath}/1`}>
            <span>{intl.formatMessage({ id: "startAgain" })}</span>
          </StyledLink>
          <StyledLink to={getRoomLink(room, lang)}>
            <span>{intl.formatMessage({ id: "finish" })}</span>
          </StyledLink>
        </div>
      </LastSlideInner>
    </LastSlideWrapper>
  )
}
