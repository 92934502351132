import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"

import ProgressBar from "./ProgressBar"

import overviewIcon from "../../images/slideshow/overview.svg"
import closeOverlaySlidedownIcon from "../../images/slideshow/close-overlay-slidedown.svg"

const Wrapper = styled.div`
  display: flex;
  margin-top: 1.125rem;
  padding: 0 1rem 0 2.125rem;
`

const Button = styled.button`
  width: 1.75rem;
  height: 1.75rem;
  margin-left: 0.875rem;
  padding: 0.1875rem;
  border: 0;
  background: none;
  opacity: 1;
`

const OverviewWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: ${props => props.bgColor};
  animation: slide-up .3s forwards;

  @keyframes slide-up {
    from { top: 100%; }
    to { top: 0; }
  }
`

const Inner = styled.div`
  max-width: 45rem;
  margin: 0 auto;
  padding: 4rem 1.25rem 3rem;
`

const CloseButton = styled.button`
  position: absolute;
  top: .625rem;
  right: .625rem;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  border: 0;
  background: none;

  img {
    display: block;
    width: auto;
    height: 100%;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .overview--logo {
    width: 45%;
    height: auto;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 500px) {
    .overview--logo {
      width: 31%;
    }
  }

  @media (min-width: 45rem) {
    .overview--logo {
      width: 13.125rem;
    }
  }
`

const List = styled.ol`
  list-style: none;
  counter-reset: overview-counter;
  margin-top: 2.5rem;
  padding: 0;

  @media (min-width: 500px) {
    margin-top: 7%;
  }
`

const ListItem = styled.li`
  position: relative;
  height: 10rem;
  border: solid rgba(255, 255, 255, .4);
  border-width: 0 0 1px;
  counter-increment: overview-counter;
  color: white;

  &:first-child {
    border-top-width: 1px;
  }

  &::before {
    content: counter(overview-counter);
    position: absolute;
    text-align: center;
    top: 50%;
    left: 0;
    z-index: -1;
    transform: translateY(-50%) translateX(-25%);
    font-size: 2rem;
    width: 2.8125rem;
    height: 2.8125rem;
    line-height: 2.8125rem;
    padding: 0.2rem;
    font-weight: bold;
    border-radius: 999px;
  }

  &.active {
    ::before {
      background: white;
      color: ${props => props.bgColor};
    }
  }

`

const StyledLink = styled(Link)`
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 1.25rem 1.25rem 1.25rem 3rem;
  border: 0;
  background: none;
  text-decoration: none;

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

export default function Overview({
  lang,
  basePath,
  currentSlide,
  slides,
  logo,
  bgColor,
}) {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Wrapper>
      <ProgressBar currentSlide={currentSlide} totalSlides={slides.length} />
      <Button onClick={() => setIsOpen(true)} aria-expanded={isOpen}>
        <img src={overviewIcon} alt={intl.formatMessage({ id: "overview" })} />
      </Button>
      {isOpen &&
        <OverviewWrapper bgColor={bgColor}>
          <CloseButton onClick={() => setIsOpen(false)}>
            <img src={closeOverlaySlidedownIcon} alt={intl.formatMessage({ id: "close" })} />
          </CloseButton>
          <Inner>
            {logo &&
              <Header>
                {logo && <img src={logo} alt="" className="overview--logo" />}
              </Header>
            }
            <List>
              {slides.map((slide, index) => (
                <ListItem key={slide.order} className={index + 1 === currentSlide && "active"} bgColor={bgColor}>
                  <StyledLink to={`/${lang}/${basePath}/${(index + 1)}`}>
                    <picture>
                      <source media="(max-width: 400px)" srcSet={`${encodeURI(slide.imageLandscape || slide.imagePortrait)}?nf_resize=fit&w=584`} />
                      <source media="(min-width: 401px)" srcSet={`${encodeURI(slide.imageLandscape || slide.imagePortrait)}?nf_resize=fit&w=652`} />
                      <img src={encodeURI(slide.imageLandscape || slide.imagePortrait)} alt={`Slide ${(index + 1)}`} />
                    </picture>
                  </StyledLink>
                </ListItem>
              ))}
            </List>
          </Inner>
        </OverviewWrapper>
      }
    </Wrapper>
  )
}
