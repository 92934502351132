import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import { lucasButtonStyle, getLucasImage, LucasLastSlideImage } from "../components/slideshows/LucasSlideshow"
import Slide from "../components/slideshows/Slide"
import LastSlide from "../components/slideshows/LastSlide"
import Nav from "../components/slideshows/Nav"
import Overview from "../components/slideshows/Overview"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import lucasLogo from "../images/slideshow/lucas/Lucas_Logo_Sve.svg"
import lucasLogoEng from "../images/slideshow/lucas/Lucas_Logo_Eng.svg"
import lucasHeading from "../images/slideshow/lucas/Lucas_Rubrik_Sve.svg"
import lucasHeadingEng from "../images/slideshow/lucas/Lucas_Rubrik_Eng.svg"

const Wrapper = styled.div`
  min-height: 100%;
  background: ${props => props.bgColor};
`

export default function LucasSlideshowTemplate({
  data: { lucasSlideshowYaml, allLucasSlideshowYaml },
  pageContext: {
    lang,
    pagePath,
    basePath,
    currentSlide,
    isLast = false,
    order,
  },
}) {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "lucasSlideshowTitle" })
  const theme = { themeColor: "#a1daf8", textColor: "black", bgColor: "#dd7400" }
  const lastSlideImage = isLast ? <LucasLastSlideImage /> : null
  const image = isLast ? null : getLucasImage(lucasSlideshowYaml)
  const logo = lang === "sv" ? lucasLogo : lucasLogoEng
  const heading = lang === "sv" ? lucasHeading : lucasHeadingEng
  const roomSymbol = (
    <img
      src={lang === "sv" ? lucasLogo : lucasLogoEng}
      alt={`${intl.formatMessage({ id: "lucasSlideshowTitle" })} ${intl.formatMessage({ id: "logotype" })}`}
    />
  )

  return (
    <Wrapper bgColor={theme.bgColor}>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={title}
        image={{ url: lucasSlideshowYaml.imageLandscape, alt: lucasSlideshowYaml.imageAltText }}
      />
      <Header
        lang={lang}
        room={3}
        color={theme.bgColor}
        roomSymbol={roomSymbol}
        isTransparent
      />
      <Overview
        lang={lang}
        basePath={basePath}
        currentSlide={currentSlide}
        slides={allLucasSlideshowYaml.nodes}
        logo={logo}
        bgColor={theme.bgColor}
      />
      {isLast ?
        <LastSlide
          lang={lang}
          basePath={basePath}
          image={lastSlideImage}
          buttonStyle={lucasButtonStyle}
        />
      :
        <Slide
          image={image}
          sound={lucasSlideshowYaml.sound}
          text={lucasSlideshowYaml.text}
          slideshowTheme={theme}
        />
      }
      <Nav
        currentSlide={currentSlide}
        isLast={isLast}
        color={theme.bgColor}
        prevLink={currentSlide > 1 ? `/${lang}/${basePath}/${currentSlide - 1}` : null}
        nextLink={!isLast ? `/${lang}/${basePath}/${currentSlide + 1}` : null}
      />
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String, $order: Float) {
    lucasSlideshowYaml(lang: { eq: $lang }, order: { eq: $order }) {
      text
      sound
      imagePortrait
      imageLandscape
      imageAltText
    }
    allLucasSlideshowYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }) {
      nodes {
        order
        imageLandscape
      }
    }
  }
`
