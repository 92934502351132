import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import lastSlideImage from "../../images/slideshow/lucas/NW_Lucas_Bild_Lands_34.jpg"

export const lucasButtonStyle = `
  position: relative;
  padding: 3px;
  border: 0;
  background: none;
  color: #183f88;
  font-size: .9rem;
  line-height: 1.3;
  font-weight: 600;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background: linear-gradient(0deg, rgba(136,181,203,1) 0%, rgba(221,241,252,1) 100%);
  }

  &:active::before {
    background: linear-gradient(0deg, rgba(221,241,252,1) 0%, rgba(136,181,203,1) 100%);
  }

  &:hover,
  &:focus {
    color: #183f88;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    padding: 9px 14px 9px;
    border-radius: 9999px;
    background: #a3dbf7;

    img {
      display: inline-block;
      vertical-align: middle;
      margin-left: 6px;
      margin-right: -2px;
      width: auto;
      height: .9rem;
    }
  }
`

export const getLucasImage = ({ imagePortrait, imageLandscape, imageAltText }) => (
  <figure>
    <picture>
      <source media="(orientation: portrait) and (max-width: 420px)" srcSet={`${imagePortrait}?nf_resize=fit&w=648`} />
      <source media="(orientation: portrait) and (min-width: 421px)" srcSet={`${imagePortrait}`} />
      <source media="(orientation: landscape) and (max-width: 680px)" srcSet={`${imageLandscape}?nf_resize=fit&w=552`} />
      <source media="(orientation: landscape) and (min-width: 681px) and (max-width: 800px)" srcSet={`${imageLandscape}?nf_resize=fit&w=672`} />
      <source media="(orientation: landscape) and (min-width: 801px)" srcSet={imageLandscape} />
      <img src={imageLandscape} alt={imageAltText} />
    </picture>
  </figure>
)

const LastSlideImage = styled.img`
  display: block;
  width: 10rem;
  height: auto;
  margin: 0 auto 2rem;

  @media (min-width: 768px) and (min-height: 768px) {
    width: 14rem;
    margin-bottom: 3.5rem;
  }

  @media (orientation: landscape) {
    margin-bottom: 0;
  }
`

export const LucasLastSlideImage = () => {
  const intl = useIntl()

  return (
    <LastSlideImage
      src={lastSlideImage}
      alt={intl.formatMessage({ id: "lucasSlideshowLastSlideImageAltText" })}
    />
  )
}
