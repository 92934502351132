import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import Arrow from "../icons/Arrow"

const StyledLink = styled(Link)`
  position: fixed;
  bottom: 1.125rem;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  border: 2px solid white;

  &.prev {
    left: 1.375rem;
    transform: rotate(180deg);
    filter: drop-shadow(0 -0.125rem 0.25rem rgba(0, 0, 0, 0.25));
  }

  &.next {
    right: 1.375rem;
    filter: drop-shadow(0 0.125rem 0.25rem rgba(0, 0, 0, 0.25));
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  svg {
    display: block;
    width: 65%;
    height: 100%;
    margin: auto;
    transform: translateX(5%);
  }
`

export default function Nav({ currentSlide, isLast, color, prevLink, nextLink }) {
  const intl = useIntl()

  return (
    <div>
      {currentSlide > 1 &&
        <StyledLink
          to={prevLink}
          className="prev"
          style={{ backgroundColor: color }}
        >
          <Arrow alt="" role="none" aria-hidden  />
          <span className="structural">{intl.formatMessage({ id: "previous"})}</span>
        </StyledLink>
      }
      {!isLast &&
        <StyledLink
          to={nextLink}
          className="next"
          style={{ backgroundColor: color }}
        >
          <Arrow alt="" role="none" aria-hidden  />
          <span className="structural">{intl.formatMessage({ id: "nextNasta"})}</span>
        </StyledLink>
      }
    </div>
  )
}
