import React from "react"
import styled from "styled-components"
import get from "lodash/get"

import AudioPlayer from "../AudioPlayer"

const Wrapper = styled.main`
  margin: auto;
  padding: 2.5rem 1.25rem 4.5rem;

  figure {
    margin: 0 auto 1rem;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  @media (orientation: portrait) {
    max-width: 40rem;
  }

  @media (orientation: landscape) {
    display: flex;
    width: 100%;
    max-width: 82rem;

    > div {
      width: calc(50% - 1rem);
      flex-shrink: 0;
    }

    > div:nth-child(1) {
      margin-right: 1rem;
    }

    > div:nth-child(2) {
      margin-left: 1rem;
    }
  }
`

const StyledAudioPlayer = styled(AudioPlayer)`
  padding: 0 0.5rem;

  @media (orientation: landscape) {
    width: 50%;
  }
`

const Text = styled.div`
  margin: 1rem auto;
  padding: 0 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: ${props => props.color};

  p {
    margin: 0;
  }
`

export default function Slide({ image, sound, slideshowTheme, text, isMarkdown }) {
  return (
    <Wrapper>
      <div>{image}</div>
      <div>
        {sound &&
          <StyledAudioPlayer src={sound} color={slideshowTheme.themeColor} />
        }
        {isMarkdown ?
          <Text color={slideshowTheme.textColor} dangerouslySetInnerHTML={{ __html: get(text, "html") }} />
        :
          <Text color={slideshowTheme.textColor}>{text}</Text>
        }
      </div>
    </Wrapper>
  )
}
