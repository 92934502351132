import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
`

const Bar = styled.div`
  margin-top: 0.5625rem;
  padding: 0.0625rem;
  height: 0.625rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 9999px;
`

const Progress = styled.div`
  height: 0.5rem;
  background: white;
  border-radius: 9999px;
`

const Text = styled.div`
  margin-top: 0.4375rem;
  font: bold 0.75rem/0.9375rem ${props => props.theme.secondaryFont};
  color: rgba(255, 255, 255, 1);
  text-align: center;
`

export default function ProgressBar({ currentSlide, totalSlides, ...props }) {
  return (
    <Wrapper {...props}>
      <Bar>
        <Progress style={{ width: `${((currentSlide - 1) / totalSlides * 100)}%` }} />
      </Bar>
      <Text>{currentSlide} / {totalSlides ? totalSlides + 1 : currentSlide}</Text>
    </Wrapper>
  )
}
